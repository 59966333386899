<template>
    <div class="site">
        <my-nav></my-nav>
        <router-view></router-view>
        <div class="contact">
            <div class="wrpr">
                <h4>CONTACT</h4>
                <hr>
                <ul>
                    <li class="icons">
                        <a href="tencent://AddContact/?fromId=45&amp;fromSubId=1&amp;subcmd=all&amp;uin=2195249372"
                           target="_blank">
                            <svg t="1657776056091" style="width: 28px;height: 28px" class="icon" viewBox="0 0 1024 1024"
                                 version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3867" width="200" height="200">
                                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64z m210.5 612.4c-11.5 1.4-44.9-52.7-44.9-52.7 0 31.3-16.2 72.2-51.1 101.8 16.9 5.2 54.9 19.2 45.9 34.4-7.3 12.3-125.6 7.9-159.8 4-34.2 3.8-152.5 8.3-159.8-4-9.1-15.2 28.9-29.2 45.8-34.4-35-29.5-51.1-70.4-51.1-101.8 0 0-33.4 54.1-44.9 52.7-5.4-0.7-12.4-29.6 9.4-99.7 10.3-33 22-60.5 40.2-105.8-3.1-116.9 45.3-215 160.4-215 113.9 0 163.3 96.1 160.4 215 18.1 45.2 29.9 72.8 40.2 105.8 21.7 70.1 14.6 99.1 9.3 99.7z"
                                      p-id="3868"></path>
                            </svg>
                        </a>
                    </li>
                    <li class="icons">
                        <a href="https://steamcommunity.com/id/2195249372" target="_blank">
                            <svg t="1657772772460" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="1403" width="200" height="200">
                                <path d="M1008 512c0 274-222.4 496-496.8 496-227.6 0-419.2-152.6-478-360.8l190.4 78.6c12.8 64.2 69.8 112.8 137.8 112.8 78.4 0 143.8-64.8 140.4-147l169-120.4c104.2 2.6 191.6-81.8 191.6-187 0-103.2-84-187-187.4-187s-187.4 84-187.4 187v2.4L369.2 558c-31-1.8-61.4 6.8-87 24.2L16 472.2C36.4 216.8 250.2 16 511.2 16 785.6 16 1008 238 1008 512zM327.4 768.6l-61-25.2a105.58 105.58 0 0 0 54.4 51.6c53.8 22.4 115.6-3.2 138-56.8 10.8-26 11-54.6 0.2-80.6-10.8-26-31-46.4-57-57.2-25.8-10.8-53.4-10.4-77.8-1.2l63 26c39.6 16.4 58.4 61.8 41.8 101.4-16.6 39.8-62 58.4-101.6 42z m347.6-259.8c-68.8 0-124.8-56-124.8-124.6s56-124.6 124.8-124.6 124.8 56 124.8 124.6-55.8 124.6-124.8 124.6z m0.2-31.2c51.8 0 93.8-42 93.8-93.6 0-51.8-42-93.6-93.8-93.6s-93.8 42-93.8 93.6c0.2 51.6 42.2 93.6 93.8 93.6z"
                                      fill="" p-id="1404"></path>
                            </svg>
                        </a>
                    </li>
                    <li class="icons">
                        <a href="https://www.pixiv.net/" target="_blank">
                            <svg t="1657772886902" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" p-id="2525" width="200" height="200">
                                <path d="M701.515 301.152c-44.44-46.092-100.245-64.919-163.08-65.107-29.552-0.089-58.595 4.617-87.095 12.455-28.961 7.965-56.673 18.839-81.996 35.306-3.347 2.176-4.69 4.686-4.695 8.694-0.034 29.713-0.333 59.424-0.426 89.137-0.085 27.263-0.018 54.527-0.021 81.79-0.004 56.836 0.053 113.674-0.097 170.51-0.015 5.326 1.57 8.393 6.639 10.576 17.881 7.7 36.245 13.85 55.161 18.37 30.808 7.36 62.082 10.876 93.715 12.36 33.75 1.583 66.83-1.28 98.832-12.378 61.328-21.265 105.46-61.217 129.447-121.99 15.492-39.25 17.417-80.058 10.877-121.393-7.146-45.173-25.384-85.268-57.26-118.33z"
                                      fill="#2c2c2c" p-id="2526"></path>
                                <path d="M512 17.482C238.886 17.482 17.482 238.886 17.482 512S238.886 1006.518 512 1006.518 1006.518 785.114 1006.518 512 785.114 17.482 512 17.482z m327.127 530.516c-18.962 47.58-50.687 84.816-92.709 113.517-43.734 29.872-92.452 46.554-144.647 53.74-66.355 9.136-131.115 0.236-195.108-17.307-12.485-3.423-24.767-7.483-37.009-11.676-4.732-1.62-5.413-1.201-5.42 3.878-0.049 33.583 0.004 67.165-0.069 100.748-0.008 3.782 1.314 5.86 5.093 7.004 7.233 2.187 14.26 5.003 20.384 9.602 3.594 2.699 6.19 5.968 5.824 10.873-0.43 5.752-3.64 8.957-9.432 8.968-19.872 0.039-39.744 0.014-59.617 0.014-19.41 0.006-38.82-0.103-58.23 0.082-4.736 0.045-8.025-1.288-9.814-5.842-1.798-4.575-0.752-8.45 2.566-11.847 5.698-5.833 13.181-8.404 20.593-11 7.625-2.668 7.8-0.933 7.8-10.86-0.02-151.738-0.02-303.476-0.076-455.215-0.001-2.353 1.227-5.726-1.09-6.829-2.297-1.094-4.167 1.976-6 3.442-22.584 18.058-43.474 37.783-60.052 61.694-0.527 0.76-1.03 1.537-1.588 2.273-5.878 7.727-9.827 15.6-5.76 25.855 1.946 4.91 1.707 10.59 0.282 15.968-0.937 3.532-2.436 6.64-5.44 8.865-5.016 3.718-8.469 3.16-11.788-2.085-12.847-20.308-25.59-40.682-38.532-60.928-2.117-3.313-1.999-5.634 0.715-8.492 17.758-18.692 37.058-35.675 57.256-51.635 51.148-40.413 106.911-72.725 168.335-94.902 40.036-14.455 81.234-23.72 123.709-27.312 49.596-4.194 98.488-0.765 146.365 13.295 50.8 14.919 95.584 40.146 131.966 79.103 36.324 38.896 58.162 84.856 65.163 137.62 5.425 40.866 1.694 80.839-13.67 119.389z"
                                      fill="#2c2c2c" p-id="2527"></path>
                            </svg>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer">
            <hr>
            <hr>
            <hr>
            <div class="copyright">
                <ul>
                    <li>Copyright © 2023 yonnxu</li>
                    <li><a href="http://mail.qq.com/cgi-bin/qm_share?t=qm_mailme&amp;email=2195249372@qq.com"
                           target="_blank">2195249372@qq.com</a>
                        </li>
                           <li>
                            <a   href="https://beian.miit.gov.cn/" target="_blank">湘ICP备2023002522号</a>
                           </li>
                </ul>
            </div>
        </div>
        <my-aplayer/>
    </div>
</template>
<script>
    import myNav from "../components/index/Nav";
    import MyAplayer from "../components/index/MyAplayer";

    export default {
        name: "Index",
        components: {myNav, MyAplayer},
    }
</script>

<style lang="less" scoped>
    .site {
        display: flex;
        min-height: 100vh; /* 没有元素时，也把页面撑开至100% */
        flex-direction: column;
    }

    .footer {
        position: relative;
        overflow: hidden;
        padding: 4em 0 4em 0;
        color: rgba(82, 81, 86, 0.75);

        hr {
            border: none;
            padding: 0;
            margin: 0 0 1px 0;
            border-top: 1px solid #23aaff;
        }

        .copyright {
            width: 100%;
            margin: 0 auto;
            text-align: center;
            color: rgba(82, 81, 86, 0.75);
            padding-top: 50px;
            font-size: 0.8em;
            cursor: default;

            ul {
                height: 1em;
                line-height: 1em;
                list-style: none;

                li {
                    display: inline-block;
                }

                li:nth-last-child(2), li:nth-last-child(1) {
                    border-left: solid 1px rgba(82, 81, 86, 0.75);
                    padding-left: 1.25em;
                    margin-left: 1.25em;

                    a {
                        color: rgba(82, 81, 86, 0.75);
                        text-decoration: none;
                    }

                }
            }
        }
    }

    .contact {
        position: fixed;
        z-index: 100;
        top: 0;
        right: 0;
        height: 100%;

        .wrpr {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translate(0, -50%);
            overflow: hidden;

            hr {
                border: none;
                width: 1px;
                height: 50px;
                background-color: #000;
                margin: 80px 0 26px 42px;
            }

            h4 {
                transform: rotate(90deg) translate(50%, 0);
                margin: 0px -24px 0 0;
                font-weight: 300;
                font-size: 14px;
            }

            ul {
                position: relative;
                right: 2px;
            }

            .icons {
                text-align: left;
                margin: 0 0 12px 10px;

                a {
                    display: block;

                    svg {
                        position: relative;
                        width: 25px;
                        height: 25px;
                        transition: all 0.25s ease-out;
                        /*opacity: 0;*/
                        top: 0;
                        left: 10px;
                        opacity: .5;
                        padding: 10px;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }

            }
        }
    }

</style>
