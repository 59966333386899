<template>
    <div>
        <meting-js
                server="netease"
                type="playlist"
                id="7477755327"
                fixed="true"
                lrcType="2"
                autoplay="false"
                loop="all"
                order="random"
                preload="auto"
                list-folded="ture"
                list-max-height="300px"
                >
        </meting-js>
        <div id="player"></div>
    </div>
</template>

<script>
    export default {
        name: "MyAplayer",
    }
</script>

<style scoped>

</style>