<template>
    <div ref="nav" class="nav">
        <div class="container " style="">
            <router-link to="/home" class="item" style="flex: 1">
                <span>首页</span>
            </router-link>
            <el-dropdown style="flex: 1" class="item">
				<span class="el-dropdown-link">
					<i class="idea icon"></i>分类<i class="el-icon-arrow-down el-icon--right"></i>
				</span>
                <el-dropdown-menu slot="dropdown">
                    <router-link v-for="item in categoryList" :key="item.id" :to="`/category/${item.id}`" style="text-decoration: none"><el-dropdown-item>{{item.name}}</el-dropdown-item></router-link>
                </el-dropdown-menu>
            </el-dropdown>
            <router-link to="/television" class="item" style="flex: 1">
                <span>追剧</span>
            </router-link>
            <router-link to="/about" class="item" style="flex: 1">
                <span>关于</span>
            </router-link>
        </div>
    </div>
</template>

<script>
    import {getNav} from '../../api/nav'

    export default {
        name: "Nav",
        data() {
            return {
                categoryList:[]
            }
        },

        created(){
          this.getData()
        },
        methods: {
            getData(){
                getNav().then(res=>{
                    this.categoryList=res.data
                })
            },
        }
    }
</script>

<style lang="less" scoped>

    .nav {
        color: #888888;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        padding: 1em 0 1em 0;
        z-index: 999;
        overflow: hidden;
        display: block;

        .container {
			line-height: 8px;
            position: relative;
            display: inline-block;
            margin: 0;
            height: 21px;
            border-left: solid 1px rgba(192, 192, 192, 0.35);
            border-right: solid 1px rgba(192, 192, 192, 0.35);
            list-style: disc;
			padding-left: 1em;
			padding-right: 0.5em;
            text-align: center;


			.item{
				white-space: nowrap;
				display: inline-block;
				text-decoration:none;
				color: inherit;
				font: inherit;
				font-size: 100%;
				margin: -9px 0.5em 0 0.5em;
				border-radius: 0.5em;
				padding: 0.85em;
				border: solid 1px transparent;
				transition: color 0.35s ease-in-out, border-color 0.35s ease-in-out;

                :hover{
                    color: rgb(35, 170, 255);
                }

				:nth-child(1){
					cursor: pointer;
				}

				.el-icon-arrow-down{
					display: none;
				}
		}
        }
    }

    .container:before {
        content: '';
        display: block;
        width: 300%;
        position: absolute;
        left: 100%;
        margin-left: 1px;
        top: 50%;
        margin-top: -2px;
        height: 5px;
        border-top: solid 1px rgba(192, 192, 192, 0.35);
        border-bottom: solid 1px rgba(192, 192, 192, 0.35);
    }

    .container:after {

        content: '';
        display: block;
        width: 300%;
        position: absolute;
        right: 100%;
        margin-right: 1px;
        top: 50%;
        margin-top: -2px;
        height: 5px;
        border-top: solid 1px rgba(192, 192, 192, 0.35);
        border-bottom: solid 1px rgba(192, 192, 192, 0.35);
    }
    .link-style{
        text-decoration: none;
        color: #606266;
    }
    .link-style:hover{
        color: #66B1FF;
    }

</style>